/* eslint no-mixed-spaces-and-tabs: */
/* eslint no-unused-vars: */
/* eslint  no-undef: */
/* eslint-disable */
import Swiper from 'swiper/bundle';
import AOS from 'aos';
import 'lity';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'
// import lgclose from 'lightGallery'
import fancybox from '@fancyapps/fancybox';

export default {
  init() {
    // JavaScript to be fired on all pages

    jQuery(document).foundation();

    $(window).scroll(function() {
        var theta = $(window).scrollTop() / 100 % Math.PI;
        $('.compassimg').css({ transform: 'rotate(' + theta + 'rad)' });    
    });

    AOS.init();
    //  var names = [];
    // $(".swiper-wrapper .swiper-slide").each(function(i) {
    //     names.push($(this).attr("data-title"));
    // });
    var thumbnailSwiper = new Swiper(".thumbnailSwiper", {
      spaceBetween: 40, 
      slidesPerView: "auto",
      freeMode: true,
      watchSlidesProgress: true,
    });
    var heroSwiper = new Swiper(".heroSwiper", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".thumb-next",
        prevEl: ".thumb-prev",
      },
      thumbs: {
        swiper: thumbnailSwiper,
      },
    });

    lightGallery(document.getElementById('imageGallery'));
    lightGallery(document.getElementById('lightGallery'));

    
    var gallerySwiper = new Swiper(".gallerySwiper", {
        slidesPerView: 2.9,
        spaceBetween: 20,
        pagination: {
          el: ".gallery-progressbar",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1.4,
                spaceBetween: 20,
            },
            640: {
              slidesPerView: 1.7,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1299: {
              slidesPerView: 2.95,
              spaceBetween: 20,
            },
        },
    });
    var testimonialSwiper = new Swiper(".testimonialSwiper", {
        slidesPerView: 1,
        spaceBetween: 50,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    var fullSLider = new Swiper(".fullSLider", {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      navigation: {
        nextEl: ".fullSLider-button-next",
        prevEl: ".fullSLider-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      
    });
    $(document).on('click', '#desktop-nav a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    // $('.border-top-btn.page-scroll-id a[href^="#"]').each(function() {
    //   $(this).on('click', function (event) {
    //     event.preventDefault();
    //           //find the button's parent, in this case it is the section wrapper #about
    //           var nextSection = $(this).closest('section').next();
    //           $('html, body').animate({
    //               scrollTop: nextSection.offset().top - 130
    //           }, 1000 );
    //       });
    //   });

    //   $('.btn-flex .btn-transparent').each(function() {
    //       $(this).on('click', function (event) {
    //         event.preventDefault();
    //           //find the button's parent, in this case it is the section wrapper #about
    //           var nextSection = $(this).closest('section').next();
    //           $('html, body').animate({
    //               scrollTop: nextSection.offset().top - 130
    //           }, 1000 );
    //       });
    //   });

    $(".ctm-search a").click(function () {
        $('.topbar__search').toggleClass('open');
        $('.topbar__search').toggleClass('search-open');   
    }); 
    jQuery(document).on('click', '.inner-banner .btn_scroll_down', function (event) {
        event.preventDefault();

        jQuery('html, body').animate({
            scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 100
        }, 500);
    });

    jQuery(document).on('click', '.page-scroll-id a[href^="#"]', function (event) {
      event.preventDefault();

      jQuery('html, body').animate({
          scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 300
      }, 500);
  });

    // $('html, body').animate({ scrollTop: element.offset().top - $('.header').height() }, { duration: 400 });
    
    if (jQuery('.latest-news-grid .columns').length) { 
      jQuery('.latest-news-grid .columns').slice(0, 9).show();
      jQuery('.lead_more').click(function(e){
        e.preventDefault();
        jQuery('.latest-news-grid .columns:hidden').slice(0, 3).show();
        if (jQuery('.latest-news-grid .columns:hidden').length == 0) { 
          jQuery('.lead_more').hide();
        }
      }); 
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() >= 100) {
          $('.header').addClass('is-sticky');
          $('body').addClass('is-sticky');
      } else {
          $('.header').removeClass('is-sticky');
          $('body').removeClass('is-sticky');
      }
    });
    //remove empty P tag
    $('p').each(function() {
      var $this = $(this);
      if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
        $this.remove();
    });
    $('p span').each(function() {
      var $this = $(this);
      if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
        $this.remove();
    });
    
    $('.hamburger').click(function(){
      $('.sidebar-menu').toggleClass('active');
      $('.hamburger').toggleClass('active');
      $("body,html").toggleClass("menu-open");
    });
    
    $('#banner_readmore').click(function(){
      $('#banner_readmore').toggleClass('active');
      $('.more_description').slideToggle(300);
    });

    /* lightGallery(document.getElementById('lightGallery'), {
      plugins: [lgVideo],
      videojs: true,
      videojsOptions: {
          muted: true,
      },
  }); */

    lightGallery(document.getElementById('lightGallerys'), {
      plugins: [lgVideo],
      videojs: true,
      videojsOptions: {
          muted: true,
      },
  });



  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
